.wrapper {
  background-color: #1F1F1F;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.logo {
  height: 40px;
}
