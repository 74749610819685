.wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    flex-grow: 1;
}

.right {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    height: 100%;
}

.title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
    margin-top: 80px;
}

.title__text {
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: #FFFFFF;
}

.title__subtext {
    font-weight: bold;
    font-size: 60px;
    line-height: 40px;
    color: #888888;
}

.title__date {
    display: flex;
    align-items: baseline;
    margin-left: 48px;
}

.title__day {
    font-weight: 400 !important;
    font-size: 160px;
}

.title__month {
    font-weight: 400;
    font-size: 40px;
}

.main {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #888888;
    display: flex;
    flex-direction: column;
    width: 92%;
    text-align: justify;
}

.main > div:not(:first-child) {
    margin-top: 16px;
}

.signature {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    justify-content: flex-end;
    width: 92%;
}

.selected {
    color: #24671F;
    font-weight: bold;
}

@media screen and (max-width: 736px) {
    .left {
        display: none;
    }

    .right {
        width: 100%;
        margin: 0;
    }

    .title {
        flex-direction: column-reverse;
        align-items: center;
    }

    .title__date {
        margin: 0;
    }

    .textWrapper {
        display: flex;
        align-items: center;
        margin-top: 40px;
        flex-direction: column;
    }
}
