@font-face {
  font-family: 'Montserrat';
  src: url('/src/assets/fonts/Montserrat-Regular.ttf');
}

body {
  font-family: Montserrat, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
  color: #AFAFAF;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

input, button {
  font-family: Montserrat, 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
