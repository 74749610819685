.wrapper {
    display: flex;
    margin: 16px 0 0 12px;
    width: 92%;
    height: 40px;
}

.input {
    background: #434343;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    width: 70%;
    font-size: 16px;
    padding: 10px;
    margin-right: 16px;
}

.input:focus {
    outline: none;
    box-shadow:  0 0 8px rgba(255,255,255, 0.7);
}

.button {
    background: #24671F;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    width: 30%;
    font-size: 16px;
    padding: 10px;
}

.button:focus, .button:hover {
    cursor: pointer;
    outline: none;
}

.button:hover {
    cursor: pointer;
    outline: none;
    box-shadow:  0 0 8px rgba(36,103,31, 0.7);
}

.error {
    color: red;
    margin: 4px 0 0 12px;
}

@media screen and (max-width: 736px) {
    .wrapper {
        flex-direction: column;
        width: 95%;
        margin-right: 12px;
    }

    .input {
        width: 96%;
        margin-bottom: 12px;
    }

    .button {
        width: 100%;
    }
}
